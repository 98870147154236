const hostPC = '/classroom';

export default {
  linkToCore(to = '/') {
    return hostPC + to;
  },
  /**
   * Перейти в ЛК
   */
  toCore() {
    window.location = hostPC;
  },

  /**
   * Перейти на курсы
   */
  linkToSchool() {
    return `${hostPC}/school/`;
  },
  toSchool() {
    window.location = this.linkToSchool();
  },

  linkToEducationFiles() {
    return `${hostPC}/education-files`;
  },
};
