export default {

  data: () => ({
    titleForSend: '',
    name: '',
    phone: '',
    email: '',
    sending: false,
    submitError: [],

    checkValidate: false,
  }),

  watch: {
    phone() {
      const idx = this.submitError.findIndex((item) => (
        item.objectField === 'phone' && item.message === 'уже существует'
      ));

      if (idx > -1) {
        this.submitError = [
          ...this.submitError.slice(0, idx),
          ...this.submitError.slice(idx + 1),
        ];
      }
    },
    email() {
      const idx = this.submitError.findIndex((item) => (
        item.objectField === 'email' && item.message === 'уже существует'
      ));

      if (idx > -1) {
        this.submitError = [
          ...this.submitError.slice(0, idx),
          ...this.submitError.slice(idx + 1),
        ];
      }
    },
  },

  methods: {
    clearSubmitError(field) {
      this.submitError = this.submitError.filter((e) => e.objectField !== field);
    },
    validate() {
      let valid = true;

      this.$v.$touch();
      if (!this.valid) valid = false;

      const nameField = this.$refs.nameRegistrationField || this.$refs.nameField;
      if (nameField) {
        if (!nameField.validate()) valid = false;
      }

      if (this.$refs.emailField) {
        if (!this.$refs.emailField.validate()) valid = false;
      }

      if (this.$refs.phoneField) {
        if (!this.$refs.phoneField.validate()) valid = false;
      }

      if (this.$refs.emailField) {
        if (!this.$refs.emailField.validate()) valid = false;
      }

      return valid;
    },
    submit() {
      if (this.sending) return;
      if (!this.validate()) return;

      this.submitError = [];

      this.$emit('send');
      this.sending = true;

      this.$api
        .register({
          name: this.name,
          email: this.email,
          phone: this.phone.replace('+', ''),
          emailMe: true,
          landCode: this.landCode,
          formTitle: this.titleForSend,
        })
        .then((data) => {
          if (!data.success) {
            this.$emit('error');
            this.submitError = data.errors;
          } else {
            this.$store.dispatch('login', data.user);
            if (this.afterRegister) {
              return this.afterRegister(data.user);
            }
            this.$root.$emit('registerDone', data.user);
            // https://jr.synergy.ru/browse/ESK-3628
            window.location = '/classroom';
          }
          return true;
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
